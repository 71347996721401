import { modules } from "~/config/common/modules.json"
// Define your breadcrumb logic based on the route
const useBreadcrumbs = () => {
  const router = useRouter();
  const route = useRoute();

  // Check if a path has a corresponding index page
  const hasIndexPage = (path: any) => {
    if (modules.includes(path)) {
      return true;
    }
    try {
      const matchedRoute = router.resolve(path);
      return matchedRoute.name !== null;
    } catch {
      return false;
    }
  };

  const breadcrumbs = computed(() => {
    const pathSegments = route.path.split("/").filter(Boolean);
    const validBreadcrumbs: any[] = [];

    pathSegments.forEach((segment: any, index: any) => {
      const href = `/${pathSegments.slice(0, index + 1).join("/")}`;
      const label = segment.charAt(0).toUpperCase() + segment.slice(1);

      // Only add to breadcrumbs if the route has an index page
      if (hasIndexPage(href)) {
        validBreadcrumbs.push({
          label: label === "Index" ? "Dashboard" : label,
          href,
          isCurrent: index === pathSegments.length - 1,
        });
      }
    });

    return validBreadcrumbs;
  });

  return {
    breadcrumbs,
  };
};

export default useBreadcrumbs;
